// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import Carousel from '../Carousel';
import img1 from '../../assets/imagens/capa-site.png'
import Produto from '../Produto';
import './Home.css';
function Home() {
  const title = "Street Fighter";
  const footerText = "© 2024 All rights reserved.";
  const images = [
    'https://via.placeholder.com/800x400.png?text=Slide+1',
    'https://via.placeholder.com/800x400.png?text=Slide+2',
    'https://via.placeholder.com/800x400.png?text=Slide+3'
  ];
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Street Fighter Painéis automotivo." />
      </Helmet>
      <Header title={title} />
      <main>
        <img width="100%" src={img1} alt="imagem 1"></img>
        <ul className='listaItens'>
          <li>
            <Produto produto={{teste:1,texto:2}}></Produto>
          </li>
          <li>
            <Produto produto={{teste:1,texto:2}}></Produto>
          </li>
          <li>
            <Produto produto={{teste:1,texto:2}}></Produto>
          </li>
          <li>
            <Produto produto={{teste:1,texto:2}}></Produto>
          </li>
        </ul>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Home;

//<Carousel images={images} />