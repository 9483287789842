// src/Produto.js
import React from 'react';
import './Produto.css';

import img1 from '../../assets/imagens/capa-site.png'
const Produto = ({ produto }) => {


  return (
    <a className='produto'>
      <div className='imagemProduto'>
        <img width="100%" src="https://streetfightermotoparts.com.br/wp-content/uploads/2024/02/WhatsApp-Image-2024-05-15-at-14.27.23-300x300.jpeg" alt="imagem 1"></img>
      </div>
      <div className='descricaoProduto'>
        <div>SF 150</div>
        <div className="precoProduto">R$ 530,00</div>
      </div>
    </a>
  );
};


export default Produto;
