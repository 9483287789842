// src/Footer.js

import React, { useState } from 'react';
import './Footer.css';
import { FaChevronRight, FaPhone, FaInstagram, FaYoutube, FaFacebook, FaTwitter, FaChevronDown, FaChevronUp, FaMapMarker,FaClock } from 'react-icons/fa';
import logoImg from '../../assets/imagens/logo_sem_fundo.png';

function Footer({ footerText }) {
  const [expandedSections, setExpandedSections] = useState({
    section1: false,
    section2: false,
    section3: false,
    section4: false,
  });

  const toggleSection = (section) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <footer className='footer'>
      <div className='colunasFooter'>
        <div>
          <h4 onClick={() => toggleSection('section1')}>
            Sobre
            <span className="toggle-icon">
              {expandedSections.section1 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </h4>
          <div className={`content ${expandedSections.section1 ? 'expanded' : ''}`}>
            
            <div className='sobreFooter'>
              A Streetfighter sempre conta com produtos a frente do seu tempo e do mercado. Desde 2011 viemos trazendo tecnologia e agora apresentamos a nova linha de produtos com conexão via celular.
            </div>
            <p className='imagemFooter'>
              
            </p>
          </div>
        </div>
        <div>
          <h4 onClick={() => toggleSection('section2')}>
            Informações de contato
            <span className="toggle-icon">
              {expandedSections.section2 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </h4>
          <div className={`content ${expandedSections.section2 ? 'expanded' : ''}`}>
            <ul className='listaFooter'>
              <li>
                <div className="circle">
                  <FaPhone className="phone-icon" />
                </div>
                <a href="tel:31991049112">(31) 9 9104-9112</a>
              </li>
              <li>
                <div className="circle">
                  <FaMapMarker className="phone-icon" />
                </div>
                Vendas via web para todo o Brasil</li>
              <li>
                
                <div className="circle">
                  <FaClock className="phone-icon" />
                </div>
                8h às 21h Segunda a Sexta</li>
            </ul>
          </div>
        </div>
        <div>
          <h4 onClick={() => toggleSection('section3')}>
            Produtos
            <span className="toggle-icon">
              {expandedSections.section3 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </h4>
          <div className={`content ${expandedSections.section3 ? 'expanded' : ''}`}>
            <ul className='linksFooter'>
              <li>
                <a href="/" className="nav-item">Inicio <FaChevronRight/> </a>
              </li>
              <li>
                <a href="/paineis" className="nav-item">Painéis <FaChevronRight/></a>
              </li>
              <li>
                <a href="/sensores" className="nav-item">sensores <FaChevronRight/></a>
              </li>
              <li>
                <a href="/molduras" className="nav-item">Molduras <FaChevronRight/></a>
              </li>
              <li>
                <a href="/acessorios" className="nav-item">Acessórios <FaChevronRight/></a>
              </li>
              <li>
                <a href="/gabarito" className="nav-item">Gabarito <FaChevronRight/></a>
              </li>
              <li>
                <a href="/instaladores" className="nav-item">Instaladores <FaChevronRight/></a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h4 onClick={() => toggleSection('section4')}>
            Redes Sociais
            <span className="toggle-icon">
              {expandedSections.section4 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </h4>
          <div className={`content ${expandedSections.section4 ? 'expanded' : ''}`}>
            <ul>
              <li><a href="https://www.youtube.com/c/StreetFightermotoBrasil"><FaYoutube className="icon" /> YouTube</a></li>
              <li><a href="https://www.facebook.com/streetfightermotoparts"><FaInstagram className="icon" /> Instagram</a></li>
              <li><a href="https://www.youtube.com/c/StreetFightermotoBrasil"><FaFacebook className="icon" /> Facebook</a></li>
            </ul>
          </div>
        </div>
      </div>
      <p>{footerText}</p>
    </footer>
  );
}

export default Footer;
