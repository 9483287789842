// src/About.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './pages/Header';
import Footer from './pages/Footer';

function About() {
  const title = "About Page";
  const footerText = "© 2024 All rights reserved.";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Learn more about us on the About Page." />
      </Helmet>
      <Header title={title} />
      <main>
        <h2>About Us</h2>
        <p>This is the about page of our application.</p>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default About;
