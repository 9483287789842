// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

function Instaladores() {
  const title = "Home Page";
  const footerText = "© 2024 All rights reserved.";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Welcome to the Home Page of our application." />
      </Helmet>
      <Header title={title} />
      <main>
        <img width="100%" src="https://images.aemelectronics.com/aem_heroslider_digitaldash.jpg" alt="imagem 1"></img>
        <h2>Bem-vindo a página de instaladores!</h2>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Instaladores;
