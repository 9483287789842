// src/Routes.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Paineis from './pages/Paineis';
import Sensores from './pages/Sensores';
import Molduras from './pages/Molduras';
import Acessorios from './pages/Acessorios';
import Gabaritos from './pages/Gabaritos';
import Instaladores from './pages/Instaladores';
import About from './About';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/paineis" element={<Paineis />} />
        <Route path="/sensores" element={<Sensores />} />
        <Route path="/molduras" element={<Molduras />} />
        <Route path="/acessorios" element={<Acessorios />} />
        <Route path="/gabarito" element={<Gabaritos />} />
        <Route path="/instaladores" element={<Instaladores />} />
        <Route path="/sobre" element={<About />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
